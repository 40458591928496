import Service from "@/lib/service";
import { AuthenticationMethod, User } from "@/interfaces/models";

export default new class CliffMarketService extends Service {

    /**
     * Registers a new user within the service.
     *
     * @param user
     * @constructor
     */
    USER_REGISTER(user: User) {
        const payload = {
            channelUniqueId: process.env.VUE_APP_CHANNEL_UNIQUE_ID,
            ...user
        }

        return this.request('USER_REGISTER', payload, 'PUBLIC');
    }

    /**
     * @param {AuthenticationMethod} authenticationMethod
     * @returns {Promise<any>}
     */
    USER_LOGIN(authenticationMethod: AuthenticationMethod) {
        const payload = {
            channelUniqueId: process.env.VUE_APP_CHANNEL_UNIQUE_ID,
            authenticationMethodType: 'AUTHENTICATION_METHOD_SYSTEM_USERNAME',
            username: authenticationMethod.username,
            password: authenticationMethod.password
        };

        return this.request('USER_LOGIN', payload, 'PUBLIC');
    }

    /**
     * @param {Object} username
     * @returns {Promise<any>}
     */
    USER_LOST_PASSWORD(username: string) {
        const payload = {
            channelUniqueId: process.env.VUE_APP_CHANNEL_UNIQUE_ID,
            username,
            passwordResetUrl: process.env.VUE_APP_DOMAIN + '/password-recovery',
            passwordResetExpiresInSeconds: 86400
        }

        return this.request('USER_LOST_PASSWORD', payload, 'PUBLIC')
    }


    /**
     * @param {string} token
     * @param {string} newPassword
     * @returns {Promise<any>}
     */
    USER_RECOVER_PASSWORD(token: string, newPassword: string) {
        const payload = {
            channelUniqueId: process.env.VUE_APP_CHANNEL_UNIQUE_ID,
            token,
            newPassword
        }

        return this.request('USER_RECOVER_PASSWORD', payload, 'PUBLIC');
    }

    /**
     * @returns {Promise<any>}
     */
    USER_GET_PROFILE() {
        return this.request('USER_GET_PROFILE');
    }

    /**
     * @param {User} user

     * @returns {Promise<any>}
     */
    USER_UPDATE_PROFILE(user: User) {
        return this.request( 'USER_UPDATE_PROFILE', user);
    }

    /**
     * @param {string} username
     * @param {string} password
     * @param {string} newPassword
     * @returns {Promise<any>}
     */
    USER_UPDATE_PASSWORD(username: string, password: string, newPassword: string) {
        return this.request('USER_UPDATE_PASSWORD', {
            username,
            password,
            newPassword
        } );
    }

};
