import { createStore } from 'vuex'
import cartModule from "@/store/modules/app/cart.module";
import loadingModule from "@/store/modules/app/loading.module";
import toastModule from "@/store/modules/app/toast.module";
import productModule from "@/store/modules/service/product.module";
import userModule from "@/store/modules/service/user.module";
import { State} from "@/interfaces/module-states";
import { Currency, Language } from "@/interfaces/application";

export default createStore({
  state: {
    language: {
      iso2: 'en',
      locale: 'en_US',
      flag: require('@/assets/img/flags/en.png')
    },
    languages: [
      {
        iso2: 'en',
        locale: 'en_US',
        flag: require('@/assets/img/flags/en.png')
      },
      {
        iso2: 'de',
        locale: 'de_DE',
        flag: require('@/assets/img/flags/de.png')
      }
    ],
    currency: {
      name: 'EUR',
      symbol: '€'
    },
    currencies: [
      {
        name: 'EUR',
        symbol: '€'
      }
    ]
  },
  getters: {
    language(state: State) {
      return state.language;
    },
    languages(state: State) {
      return state.languages
    },
    currency(state: State) {
      return state.currency;
    },
    currencies(state: State) {
      return state.currencies
    }
  },
  mutations: {
    setLanguage(state: State, language: Language) {
      state.language = language;
    },
    setCurrency(state: State, currency: Currency) {
      state.currency = currency;
    }
  },
  actions: {
  },
  modules: {
    // App
    cart: cartModule,
    loading: loadingModule,
    toast: toastModule,
    // Service
    PRODUCT: productModule,
    USER: userModule
  }
})
