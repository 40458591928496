import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import store from "@/store";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/view-shop.vue'),
    meta: {
      requiresAuth: false,
      title: { key: 'routes.home', choice: null },
      breadcrumb: [
        { name: { key: 'home', choice: null } }
      ]
    }
  },
  {
    path: '/cart',
    name: 'cart',
    component: () => import(/* webpackChunkName: "cart" */ '../views/view-cart.vue'),
    meta: {
      requiresAuth: false,
      title: { key: 'routes.cart', choice: null },
      breadcrumb: [
        { name: { key: 'cart', choice: null } }
      ]
    }
  },
  {
    path: '/account/orders',
    name: 'account-orders',
    component: () => import(/* webpackChunkName: "account-orders" */ '../views/account/view-orders.vue'),
    meta: {
      requiresAuth: false,
      title: { key: 'routes.account-orders', choice: null },
      breadcrumb: [
        { name: { key: 'account-orders', choice: null } }
      ]
    }
  },
  {
    path: '/account/addresses',
    name: 'account-addresses',
    component: () => import(/* webpackChunkName: "account-addresses" */ '../views/account/view-addresses.vue'),
    meta: {
      requiresAuth: false,
      title: { key: 'routes.account-addresses', choice: null },
      breadcrumb: [
        { name: { key: 'account-addresses', choice: null } }
      ]
    }
  },
  {
    path: '/account/payment-methods',
    name: 'account-payment-methods',
    component: () => import(/* webpackChunkName: "account-payment-methods" */ '../views/account/view-payment-methods.vue'),
    meta: {
      requiresAuth: false,
      title: { key: 'routes.account-payment-methods', choice: null },
      breadcrumb: [
        { name: { key: 'account-payment-methods', choice: null } }
      ]
    }
  },
  {
    path: '/product/:uuid',
    name: 'product-single',
    component: () => import(/* webpackChunkName: "product-single" */ '../views/product/view-product-single.vue'),
    meta: {
      requiresAuth: false,
      title: { key: 'routes.product-single', choice: null },
      breadcrumb: [
        { name: { key: 'product-single', choice: null } }
      ]
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404-not-found',
    component: () => import(/* webpackChunkName: "404-not-found" */ '../views/view-not-found.vue'),
    meta: {
      requiresAuth: false,
      title: { key: 'routes.404-not-found', choice: null },
      breadcrumb: [
        { name: { key: '404-not-found', choice: null } }
      ]
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

router.beforeEach((to, from, next) => {
  // Redirect to the login page if not logged in and the route requires auth
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters['USER/isLoggedIn']) {
      next({name: 'login'});
      return;
    }
  }

  // Always call next()
  next();
});
