import cliffMarketService from "@/services/cliff-market.service";
import getCookie from "@/lib/get-cookie";
import deleteCookie from "@/lib/delete-cookie";
import setCookie from "@/lib/set-cookie";
import { Commit } from "vuex";
import { RecoverPasswordOptions, UpdatePasswordOptions } from "@/interfaces/user-options";
import {UserState} from "@/interfaces/module-states";
import {AuthenticationMethod, User} from "@/interfaces/models";
import UserCookie from "@/interfaces/user-cookie";

const user = getCookie('user');
const profile = {};

const state = user
    ? { status: { loggedIn: true }, user, profile }
    : { status: { loggedIn: false }, user: null, profile };

const userModule = {
    namespaced: true,
    state,
    actions: {
        REGISTER({commit}: {commit: Commit}, user: User) {
            commit('loading/USER_REGISTER', true, {root: true});
            return new Promise((resolve, reject) => {
                cliffMarketService.USER_REGISTER(user)
                    .then(user => {
                        commit('loading/USER_REGISTER', false, {root: true});
                        resolve(user);
                    })
                    .catch(error => {
                        commit('clearUser');
                        commit('loading/USER_REGISTER', false, {root: true});
                        reject(error);
                    });
            });
        },
        LOGIN({commit}: {commit: Commit}, authenticationMethod: AuthenticationMethod) {
            commit('loading/USER_LOGIN', true, {root: true});
            return new Promise((resolve, reject) => {
                cliffMarketService.USER_LOGIN(authenticationMethod)
                    .then((user) => {
                        setCookie(
                            'user',
                            JSON.stringify(
                                {
                                    key: user.key,
                                    secret: user.secret,
                                    email: user.email,
                                    firstName: user.firstName,
                                    lastName: user.lastName,
                                    token: user.token,
                                    partyUniqueId: null,
                                    awsAccessKeyId: user.aws.key,
                                    awsSecretAccessKey: user.aws.secret
                                }
                            ),
                            {
                                expires: 86400
                            }
                        );

                        commit('populateUser', user);
                        commit('loading/USER_LOGIN', false, {root: true});
                        resolve(user);
                    })
                    .catch(error => {
                        commit('clearUser');
                        commit('loading/USER_LOGIN', false, {root: true});
                        reject(error);
                    });
            });
        },
        LOGOUT({commit}: {commit: Commit}) {
            commit('loading/USER_LOGOUT', true, {root: true});
            return new Promise((resolve) => {
                deleteCookie('user');
                commit('clearUser');
                commit('loading/USER_LOGOUT', false, {root: true});
                resolve(true);
            });
        },
        LOST_PASSWORD({commit}: {commit: Commit}, username: string) {
            commit('loading/USER_LOST_PASSWORD', true, {root: true});
            return new Promise((resolve, reject) => {
                cliffMarketService.USER_LOST_PASSWORD(username)
                    .then(() => {
                        commit('loading/USER_LOST_PASSWORD', false, {root: true});
                        resolve(true);
                    })
                    .catch(error => {
                        commit('loading/USER_LOST_PASSWORD', false, {root: true});
                        reject(error);
                    });
            });
        },
        RECOVER_PASSWORD({commit}: {commit: Commit}, recoverPasswordOptions: RecoverPasswordOptions) {
            commit('loading/USER_RECOVER_PASSWORD', true, {root: true});
            return new Promise((resolve, reject) => {
                cliffMarketService.USER_RECOVER_PASSWORD(
                    recoverPasswordOptions.token,
                    recoverPasswordOptions.newPassword
                )
                    .then(() => {
                        commit('loading/USER_RECOVER_PASSWORD', false, {root: true});
                        resolve(true);
                    })
                    .catch(error => {
                        commit('loading/USER_RECOVER_PASSWORD', false, {root: true});
                        reject(error);
                    });
            });
        },
        GET_PROFILE({commit}: {commit: Commit}) {
            commit('loading/USER_GET_PROFILE', true, {root: true});
            return new Promise((resolve, reject) => {
                cliffMarketService.USER_GET_PROFILE()
                    .then(profile => {
                        commit('populateProfile', profile);
                        commit('loading/USER_GET_PROFILE', false, {root: true});
                        resolve(profile);
                    })
                    .catch(error => {
                        commit('loading/USER_GET_PROFILE', false, {root: true});
                        reject(error);
                    });
            });
        },
        UPDATE_PROFILE({commit}: {commit: Commit}, user: User) {
            commit('loading/USER_UPDATE_PROFILE', true, {root: true});
            return new Promise((resolve, reject) => {
                cliffMarketService.USER_UPDATE_PROFILE(user)
                    .then(profile => {
                        commit('populateProfile', profile);
                        commit('loading/USER_UPDATE_PROFILE', false, {root: true});
                        resolve(profile);
                    })
                    .catch(error => {
                        commit('loading/USER_UPDATE_PROFILE', false, {root: true});
                        reject(error);
                    });
            });
        },
        UPDATE_PASSWORD({commit}: {commit: Commit}, updatePasswordOptions: UpdatePasswordOptions) {
            commit('loading/USER_UPDATE_PASSWORD', true, {root: true});
            return new Promise((resolve, reject) => {
                cliffMarketService.USER_UPDATE_PASSWORD(
                    updatePasswordOptions.username,
                    updatePasswordOptions.password,
                    updatePasswordOptions.newPassword
                )
                    .then(profile => {
                        commit('loading/USER_UPDATE_PASSWORD', false, {root: true});
                        resolve(profile);
                    })
                    .catch(error => {
                        commit('loading/USER_UPDATE_PASSWORD', false, {root: true});
                        reject(error);
                    });
            });
        }
    },
    mutations: {
        clearUser(state: UserState) {
            state.user = null;
        },
        populateUser(state: UserState, user: UserCookie) {
            state.user = user;
        },

        populateProfile(state: UserState, profile: object) {
            state.profile = profile;
        }
    },
    getters: {
        isLoggedIn: (state: UserState) => {
            return state.user !== null;
        }
    }
};

export default userModule;
