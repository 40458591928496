import CookieOptions from "@/interfaces/cookie-options";

export default function setCookie(name: string, value: string, options: CookieOptions) {
    const opt = options || {};
    let str = name + "=" + value;

    if (!opt.path) {
        opt.path = '/';
    }

    if (opt.expires) {
        const dateObj = new Date();
        dateObj.setSeconds(dateObj.getSeconds() + opt.expires);
        str += '; Expires=' + dateObj.toUTCString();
    }

    str += '; Path=' + opt.path;
    str += '; SameSite=None;Secure';
    document.cookie = str;
}
