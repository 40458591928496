import { createApp } from 'vue';
import App from '@/App.vue';
import '@/registerServiceWorker';
import router from '@/router';
import store from '@/store';
import i18n from "@/i18n";
import '@/assets/cartzilla/theme.scss';
import "../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js";
import { VueReCaptcha } from "vue-recaptcha-v3";
import moment from "moment";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCircleCheck, faAward } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import getCookie from "@/lib/get-cookie";

library.add(faCircleCheck, faAward);

const app = createApp(App);

// Filters
app.config.globalProperties.$filters = {
    timestamp: (date: Date) => {
        return moment(date).format('DD.MM.YYYY, H:i:s');
    },
    kebabize: (str: string) => {
        const whitelist = ['.', '-'];

        return str.split('').map((letter, idx) => {
            if (whitelist.includes(letter)) {
                return letter;
            }

            return letter.toUpperCase() === letter
                ? `${idx !== 0 ? '-' : ''}${letter.toLowerCase()}`
                : letter;
        }).join('');
    },
    currency: (amount: number) => {
        let locale = 'en-US';
        let currency = 'EUR';
        const cookieLocale  = getCookie('locale', false);
        const cookieCurrency = getCookie('currency');

        if (cookieLocale) {
            locale = cookieLocale;
        }

        if (cookieCurrency) {
            currency = cookieCurrency;
        }

        // Create our number formatter.
        const formatter = new Intl.NumberFormat(locale, {
            style: 'currency',
            currency: currency,

            // These options are needed to round to whole numbers if that's what you want.
            //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
            //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
        });

        return formatter.format(amount); /* $2,500.00 */
    }
}

/*
app.use(VueReCaptcha, {
    siteKey: process.env.VUE_APP_GOOGLE_RECAPTCHA_V3_KEY,
    loaderOptions: {
        useRecaptchaNet: true,
        useEnterprise: true,
        autoHideBadge: true
    }
});
*/
app.component('font-awesome-icon', FontAwesomeIcon);
app.use(store);
app.use(router);
app.use(i18n);
app.mount('#app');
