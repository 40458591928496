import { ProductState } from "@/interfaces/module-states";

const state: ProductState = {
    product: undefined,
    products: {
        total: 0,
        page: 0,
        list: [
            {
                uuid: '082be123-6157-4ef1-86db-39e789a21550',
                name: 'Women Colorblock Sneakers',
                description: '',
                image: require('@/assets/img/shop/catalog/01.jpg'),
                price: 154,
                priceSale: null,
                rating: 4,
                stock: 12,
                category: {
                    name: 'Sneakers & Keds'
                },
                variations: [
                    {
                        type: 'SIZE',
                        options: [7.5, 8, 8.5, 9]
                    }
                ]
            },
            {
                uuid: 'd3ca1ab4-6a44-4207-8a87-8eb2bd8fd94f',
                name: 'Cotton Lace Blouse',
                description: '',
                image: require('@/assets/img/shop/catalog/02.jpg'),
                price: 38.50,
                priceSale: 28.50,
                rating: 3.54,
                stock: 12,
                category: {
                    name: 'Women\'s T-shirt'
                },
                variations: [
                    {
                        type: 'COLOR',
                        options: ['#EAEAEB', '#D1DCEB', '#F4E6A2', '#F3DCFF']
                    },
                    {
                        type: 'SELECT',
                        options: ['XS', 'S', 'M', 'L', 'XL']
                    }
                ]
            },
            {
                uuid: 'c6f53782-0bc6-4d40-a6f6-a39f381ef635',
                name: 'Mom High Waist Shorts',
                description: '',
                image: require('@/assets/img/shop/catalog/03.jpg'),
                price: 39.50,
                priceSale: null,
                rating: 4.87,
                stock: 12,
                category: {
                    name: 'Women\'s Shorts'
                },
                variations: [
                    {
                        type: 'SIZE',
                        options: ['XS', 'S', 'M', 'L']
                    }
                ]
            },
            {
                uuid: '4102ce2a-a0a0-46d4-a6e4-e8ee0e996f46',
                name: 'Women Sports Jacket',
                description: '',
                image: require('@/assets/img/shop/catalog/04.jpg'),
                price: 68.40,
                priceSale: null,
                rating: 4.11,
                stock: 12,
                category: {
                    name: 'Sportswear'
                },
                variations: [
                    {
                        type: 'SIZE',
                        options: ['XS', 'S', 'M', 'L']
                    }
                ]
            },
            {
                uuid: '5a8500c1-ddaa-4ef4-8134-f1706e22d1f8',
                name: 'Polarized Sunglasses',
                description: '',
                image: require('@/assets/img/shop/catalog/05.jpg'),
                price: 100,
                priceSale: null,
                rating: 4.61,
                stock: 0,
                category: {
                    name: 'Men\'s Sunglasses'
                },
                variations: null
            },
            {
                uuid: '8e224bc6-c9c7-4ff3-b630-fc0f88571602',
                name: 'TH Jeans City Backpack',
                description: '',
                image: require('@/assets/img/shop/catalog/06.jpg'),
                price: 79.50,
                priceSale: null,
                rating: 3.44,
                stock: 12,
                category: {
                    name: 'Backpacks'
                },
                variations: [
                    {
                        type: 'COLOR',
                        options: ['#97947C', '#99A8BE', '#EAEAEB']
                    }
                ]
            },
            {
                uuid: '23660a5b-1b34-47ed-94c7-73fc2aac65a3',
                name: 'Two-Piece Bikini in Print',
                description: '',
                image: require('@/assets/img/shop/catalog/07.jpg'),
                price: 18.99,
                priceSale: null,
                rating: 3.78,
                stock: 12,
                category: {
                    name: 'Women\'s Swimwear'
                },
                variations: [
                    {
                        type: 'SIZE',
                        options: ['XS', 'S', 'M']
                    }
                ]
            },
            {
                uuid: 'b91c2309-f10f-4512-bce4-48062a074a53',
                name: 'Soft Panda Teddy Bear',
                description: '',
                image: require('@/assets/img/shop/catalog/08.jpg'),
                price: 14.99,
                priceSale: null,
                rating: 4.76,
                stock: 12,
                category: {
                    name: 'Kid\'s Toys'
                },
                variations: null
            },
            {
                uuid: 'be489570-bb3e-43c1-b5c8-bb0b92bb7675',
                name: 'Metallic Lipstick (Crimson)',
                description: '',
                image: require('@/assets/img/shop/catalog/09.jpg'),
                price: 12.99,
                priceSale: null,
                rating: 3.54,
                stock: 12,
                category: {
                    name: 'Cosmetics'
                },
                variations: [
                    {
                        type: 'COLOR',
                        options: ['#BD3C82', '#EBAE81', '#CA8799']
                    }
                ]
            },
            {
                uuid: '0c64c937-ce26-443b-872c-b9dff99a4f8f',
                name: '5-Pack Multicolor Bracelets',
                description: '',
                image: require('@/assets/img/shop/catalog/10.jpg'),
                price: 7.99,
                priceSale: null,
                rating: 4,
                stock: 12,
                category: {
                    name: 'Men\'s Accessories'
                },
                variations: null
            },
            {
                uuid: '6c360d67-a3ad-44cf-b353-fe0a1f4b5906',
                name: 'Soft Footbed Sandals',
                description: '',
                image: require('@/assets/img/shop/catalog/11.jpg'),
                price: 99.50,
                priceSale: null,
                rating: 4.09,
                stock: 12,
                category: {
                    name: 'Men\'s Sandals'
                },
                variations: [
                    {
                        type: 'COLOR',
                        options: ['#879FB3', '#9C6D4A', '#333333']
                    }
                ]
            },
            {
                uuid: 'fa604a5d-547e-461c-8e87-3e5f2dca6175',
                name: '3-Color Sun Stash Hat',
                description: '',
                image: require('@/assets/img/shop/catalog/12.jpg'),
                price: 25.99,
                priceSale: null,
                rating: 4,
                stock: 12,
                category: {
                    name: 'Men\'s Hats'
                },
                variations: null
            },
            {
                uuid: '226809aa-fb15-4876-9162-3f8ae854890f',
                name: 'REPLACE_ME',
                description: '',
                image: require('@/assets/img/shop/catalog/13.jpg'),
                price: 13.50,
                priceSale: 11.15,
                rating: 2.94,
                stock: 12,
                category: {
                    name: 'Men\'s T-shirts'
                },
                variations: [
                    {
                        type: 'SIZE',
                        options: ['S', 'M', 'L', 'XL']
                    }
                ]
            },
            {
                uuid: '2d457a84-c520-4bab-962e-00fbf89d49fe',
                name: 'Slim Taper Fit Jeans',
                description: '',
                image: require('@/assets/img/shop/catalog/14.jpg'),
                price: 58.99,
                priceSale: null,
                rating: 4,
                stock: 12,
                category: {
                    name: 'Men\'s Jeans'
                },
                variations: [
                    {
                        type: 'SIZE',
                        options: ['S', 'M', 'L', 'XL']
                    }
                ]
            },
            {
                uuid: 'd9338e48-5fd2-4076-b14e-247595ee688b',
                name: 'Single-breasted Trenchcoat',
                description: '',
                image: require('@/assets/img/shop/catalog/15.jpg'),
                price: 79.99,
                priceSale: null,
                rating: 3.63,
                stock: 12,
                category: {
                    name: 'Men\'s Waistcoats'
                },
                variations: [
                    {
                        type: 'SIZE',
                        options: ['M', 'L', 'XL']
                    }
                ]
            },
        ]
    }
}

const productModule = {
    namespaced: true,
    state,
    actions: {},
    mutations: {},
    getters: {}
}

export default productModule;
