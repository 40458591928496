import getCookie from '@/lib/get-cookie';
import { AxiosRequestConfig } from "axios";

export default function getBearerAuth(): AxiosRequestConfig {
    const user = getCookie('user');

    return {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + user.token
        },
        maxContentLength: Infinity,
        maxBodyLength: Infinity
    };
}
