import axios, { AxiosBasicCredentials } from "axios";
import createInfoObject from "@/lib/create-info-object";
import getBearerAuth from "@/lib/get-bearer-auth";
import ServiceResponse from "@/interfaces/service-response";

class Service {
    nodeUniqueId: string;
    auth: AxiosBasicCredentials;
    url: string;

    constructor() {
        this.nodeUniqueId = process.env.VUE_APP_NODE_UNIQUE_ID;
        this.auth = {
            username: process.env.VUE_APP_API_KEY,
            password: process.env.VUE_APP_API_SECRET
        };
        this.url = process.env.VUE_APP_SERVICE_URL + '/' + process.env.VUE_APP_SERVICE_PORTFOLIO + '/info/' + process.env.VUE_APP_SERVICE_VERSION;
    }

    /**
     * @param {String} type
     * @param {Object} payload
     * @param {String} mode
     * @param {String} context
     */
    // eslint-disable-next-line
    request(type: string,  payload?: object, context = 'USER', mode = ''): Promise<any> {
        return new Promise((resolve, reject) => {
            const requestData = createInfoObject(type, payload, context, mode);

            let auth;

            switch (context) {
                case 'USER':
                case 'ADMIN':
                    auth = getBearerAuth();
                    break;
                case 'PUBLIC':
                    auth = {
                        auth: this.auth
                    };
                    break;
            }

            axios.post(this.url, requestData, auth)
                .then((response : ServiceResponse) => resolve(response.data.data))
                .catch((error: Record<string, unknown>) => reject(error));
        });
    }
}

export default Service;
