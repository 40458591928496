import {LoadingState} from "@/interfaces/module-states";

const state = {
    USER_REGISTER: false,
    USER_LOGIN: false,
    USER_LOGOUT: false,
    USER_LOST_PASSWORD: false,
    USER_RECOVER_PASSWORD: false,
    USER_GET_PROFILE: false,
    USER_UPDATE_PROFILE: false,
    USER_UPDATE_PASSWORD: false,
}

const loadingModule = {
    namespaced: true,
    state,
    actions: {},
    mutations: {
        USER_REGISTER(state: LoadingState, loading: boolean) {
            state.USER_REGISTER = loading;
        },
        USER_LOGIN(state: LoadingState, loading: boolean) {
            state.USER_LOGIN = loading;
        },
        USER_LOGOUT(state: LoadingState, loading: boolean) {
            state.USER_LOGOUT = loading;
        },
        USER_LOST_PASSWORD(state: LoadingState, loading: boolean) {
            state.USER_LOST_PASSWORD = loading;
        },
        USER_RECOVER_PASSWORD(state: LoadingState, loading: boolean) {
            state.USER_RECOVER_PASSWORD = loading;
        },
        USER_GET_PROFILE(state: LoadingState, loading: boolean) {
            state.USER_GET_PROFILE = loading;
        },
        USER_UPDATE_PROFILE(state: LoadingState, loading: boolean) {
            state.USER_UPDATE_PROFILE = loading;
        },
        USER_UPDATE_PASSWORD(state: LoadingState, loading: boolean) {
            state.USER_UPDATE_PASSWORD = loading;
        },
    }
}

export default loadingModule;
