import { CartState } from "@/interfaces/module-states";

const state: CartState = {
    route: 'cart',
    currency: {
        name: 'EUR',
        symbol: '€'
    },
    items: []
}

const cartModule = {
    namespaced: true,
    state,
    actions: {},
    mutations: {},
    getters: {
        cartRoute(state: CartState) {
            return state.route;
        }
    }
}

export default cartModule;
